import {
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../domains/auth/services/auth.service';
import { tap } from 'rxjs';
import { Router } from '@angular/router';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const headers: { [key: string]: string } = {};
  const token = authService.token;

  if (token) headers['Authorization'] = `Bearer ${token}`;

  const request = req.clone({ setHeaders: headers });
  return next(request).pipe(
    tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && event.status === 403) {
        router.navigate(['login']);
      }
    }),
  );
};
